import { RouteObject } from 'react-router-dom';
import LazyCreateFlowWizard from '../components/create-flow-wizard/lazy-create-flow-wizard';
import LazyCreateIntegrationWizard from '../components/create-integration-wizard/lazy-create-integration-wizard';
import LazyErrorPage from '../components/error-page/lazy-error-page';
import LazyEditFlow from '../components/flows/flow-view/edit-flow/lazy-edit-flow';
import LazyFlowView from '../components/flows/flow-view/lazy-flow-view';
import LazyFlowList from '../components/flows/lazy-flow-list';
import LazyMainContainer from '../components/main-container/lazy-main-container';
import LazyMainDashboard from '../components/main-dashboard/lazy-main-dashboard';
import LazyClientSpace from '../components/main-projects/client-spaces/lazy-client-space';
import LazyIntegrationSpace from '../components/main-projects/integration-space/lazy-integration-space';
import LazyProjectSpace from '../components/main-projects/project-space/lazy-project-space';
import LazyMainSettings from '../components/main-settings/lazy-main-settings';
import LazyMicroTask from '../components/micro-task/lazy-micro-task';
import LazyProfileDashboardView from '../components/profile-dashboard/lazy-profile-dashboard-view';
import LazyApiKeyManagementView from '../components/security-management-view/api-key-management-view/lazy-api-key-management-view';
import LazyRoleManagementView from '../components/security-management-view/role-management-view/lazy-role-management-view';
import LazySecretManagementView from '../components/security-management-view/secret-management-view/lazy-secret-management-view';
import LazySecretEditView from '../components/security-management-view/secret-management-view/secret-edit-view/lazy-secret-edit-view';
import LazyUserManagementView from '../components/security-management-view/user-management-view/lazy-user-management-view';
import LazyTriggerView from '../components/triggers/lazy-trigger-view';
import LazyTriggerEditView from '../components/triggers/trigger-edit-view/lazy-trigger-edit-view';
import LazyUnauthorizedPage from '../components/unauthorized-page/lazy-unauthorized-page';
import AppRoutes from '../enums/app-routes';
import ProtectedRoute from './protected-route/protected-route';

const privateRoutes: RouteObject[] = [
	{
		path: AppRoutes.Root,
		element: (
			<ProtectedRoute>
				<LazyMainContainer />
			</ProtectedRoute>
		),
		errorElement: <LazyErrorPage />,
		children: [
			{
				path: AppRoutes.Dashboard,
				element: (
					<ProtectedRoute>
						<LazyMainDashboard />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.ClientSpaceRoute(),
				element: (
					<ProtectedRoute>
						<LazyClientSpace />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.ProjectSpace(),
				element: (
					<ProtectedRoute>
						<LazyProjectSpace />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.IntegrationSpace(),
				element: (
					<ProtectedRoute>
						<LazyIntegrationSpace />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.CreateFlowWizard,
				element: (
					<ProtectedRoute>
						<LazyCreateFlowWizard />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.CreateIntegrationWizard(),
				element: (
					<ProtectedRoute>
						<LazyCreateIntegrationWizard />
					</ProtectedRoute>
				),
			},

			{
				path: AppRoutes.Settings,
				element: (
					<ProtectedRoute>
						<LazyMainSettings />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.FlowList,
				element: (
					<ProtectedRoute>
						<LazyFlowList />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.Triggers,
				element: (
					<ProtectedRoute>
						<LazyTriggerView />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.TriggerEditView(),
				element: (
					<ProtectedRoute>
						<LazyTriggerEditView />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.ConfigFlowView(),
				element: (
					<ProtectedRoute>
						<LazyFlowView />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.FlowTask(),
				element: (
					<ProtectedRoute>
						<LazyMicroTask />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.IntegrationTask(),
				element: (
					<ProtectedRoute>
						<LazyMicroTask />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.UserManagement(),
				element: (
					<ProtectedRoute>
						<LazyUserManagementView />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.RoleManagement(),
				element: (
					<ProtectedRoute>
						<LazyRoleManagementView />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.ApiKeyManagement(),
				element: (
					<ProtectedRoute>
						<LazyApiKeyManagementView />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.SecretsManagement(),
				element: (
					<ProtectedRoute>
						<LazySecretManagementView />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.SecretEdit(),
				element: (
					<ProtectedRoute>
						<LazySecretEditView />
					</ProtectedRoute>
				),
			},
			{
				path: AppRoutes.ProfileDashboard(),
				element: (
					<ProtectedRoute>
						<LazyProfileDashboardView />
					</ProtectedRoute>
				),
			},
		],
	},
	{
		path: AppRoutes.ConfigFlowEditView(),
		element: (
			<ProtectedRoute>
				<LazyEditFlow />
			</ProtectedRoute>
		),
		errorElement: <LazyErrorPage />,
	},
	{
		path: AppRoutes.IntegrationSpaceEdit(),
		element: (
			<ProtectedRoute>
				<LazyEditFlow />
			</ProtectedRoute>
		),
		errorElement: <LazyErrorPage />,
	},
	{
		path: AppRoutes.Unauthorized(),
		element: (
			<ProtectedRoute>
				<LazyUnauthorizedPage />
			</ProtectedRoute>
		),
	},
];

export default privateRoutes;
