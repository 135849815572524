import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import LightTooltipProps from './light-tooltip-props';
import TooltipPlacement from './tooltip-placement.enum';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.text.primary,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.text.primary,
	},
}));

export default function LightTooltip({
	title,
	placement,
	children,
	enterDelay,
}: LightTooltipProps) {
	return (
		<StyledTooltip
			title={title}
			placement={placement ?? TooltipPlacement.Bottom}
			enterDelay={enterDelay ?? 500}
		>
			{children}
		</StyledTooltip>
	);
}
