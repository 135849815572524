import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import TranslationNamespaces from '../../../localization/translation-namespaces';

export default function LoadingComponent() {
	const { t } = useTranslation(TranslationNamespaces.Root);

	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<CircularProgress />
				<Typography>{t('Loading')}</Typography>
			</div>
		</div>
	);
}
