import { Navigate } from 'react-router-dom';
import { GetSessionUser } from '../../components/main-container/common-functions';
import AppRoutes from '../../enums/app-routes';
import UnprotectedRouteProps from './unprotected-route-props';

const UnprotectedRoute = ({ children }: UnprotectedRouteProps): JSX.Element => {
	if (GetSessionUser()) {
		return <Navigate to={AppRoutes.Dashboard} replace />;
	}

	return children;
};

export default UnprotectedRoute;
