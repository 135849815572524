import { Navigate } from 'react-router-dom';
import { GetSessionUser } from '../../components/main-container/common-functions';
import AppRoutes from '../../enums/app-routes';
import ProtectedRouteProps from './protected-route-props';

const ProtectedRoute = ({ children }: ProtectedRouteProps): JSX.Element => {
	if (!GetSessionUser()) {
		return <Navigate to={AppRoutes.Login} replace />;
	}

	return children;
};

export default ProtectedRoute;
