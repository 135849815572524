import { Navigate, RouteObject } from 'react-router-dom';
import LazyErrorPage from '../components/error-page/lazy-error-page';
import LazyForgotPassword from '../components/main-container/forgot-password/lazy-forgot-password';
import LazySignIn from '../components/main-container/sign-in/lazy-sign-in';
import LazySignUp from '../components/main-container/sign-up/lazy-sign-up';
import AppRoutes from '../enums/app-routes';
import UnprotectedRoute from './unprotected-route.tsx/unprotected-route';

const publicRoutes: RouteObject[] = [
	{
		path: AppRoutes.Login,
		element: (
			<UnprotectedRoute>
				<LazySignIn />
			</UnprotectedRoute>
		),
		errorElement: <LazyErrorPage />,
	},
	{
		path: AppRoutes.SignUp,
		element: (
			<UnprotectedRoute>
				<LazySignUp />
			</UnprotectedRoute>
		),
	},
	{
		path: AppRoutes.ForgotPassword,
		element: (
			<UnprotectedRoute>
				<LazyForgotPassword />
			</UnprotectedRoute>
		),
	},
	{
		path: '*',
		element: <Navigate to={AppRoutes.Root} replace={true} />,
	},
];

export default publicRoutes;
