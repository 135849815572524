export const cacheStaleTime = 1000 * 60 * 2;
export const standardInputDebounce = 400;
export const mainAppBarHeight = 64;
export const mainToolbarBarHeight = 64;
export const bottomToolbarHeight = 40;
export const panelHeaderHeight = 60;
export const snackBarStorageID = 'snackbar-storage';
export const buttonStorageID = 'button-storage';
export const breadcrumbLinkStorageID = 'breadcrumb-link-storage';
export const selectedMapperTabKey = 'DataMapperSelectedTab';
