import { createTheme } from '@mui/material';
import { bottomToolbarHeight, mainAppBarHeight, mainToolbarBarHeight } from '../app/constants';
import baseTheme from './base-theme';

//https://zenoo.github.io/mui-theme-creator/
export const defaultLightTheme = createTheme({
	...baseTheme,
	palette: {
		mode: 'light',
		primary: {
			100: '#E4E9FF',
			200: '#DAE0FF',
			300: '#CFD7FF',
			400: '#BFC9FF',
			500: '#B0BDFF',
			600: '#A4B1F8',
			700: '#98A7F5',
			800: '#899AF3',
			900: '#6E8CF7',
			main: '#B0BDFF',
			light: 'rgb(191, 202, 255)',
			dark: 'rgb(123, 132, 178)',
			contrastText: 'rgba(0, 0, 0, 0.87)',
		},
		divider: '#E7EBF0',
		common: {
			black: '#000000',
			white: '#ffffff',
			custom: {
				iHubBlue: '#3D4157',
				navActiveBackground: '#545A79',
				componentBackground: '#EDEDED',
				stepperBackground: '#DFDBD9',
				warningBackground: 'rgba(241, 169, 103, 0.06)',
				errorBackground: 'rgba(232, 78, 98, 0.06)',
				linkText: '#3A55E2',
			},
		},
		text: {
			primary: '#343749',
			secondary: '#373737',
			disabled: 'rgba(0, 0, 0, 0.38)',
		},
		grey: {
			50: '#F3F6F9',
			100: '#E7EBF0',
			200: '#FAFAFA',
			300: '#F5F5F5',
			400: '#B2BAC2',
			500: '#A0AAB4',
			600: '#6F7E8C',
			700: '#3E5060',
			800: '#2D3843',
			900: '#434343',
			A100: '#343749',
			A200: '#1F1F1F',
			A400: '#141414',
			A700: '#616161',
		},
		error: {
			50: '#FFF0F1',
			100: '#FFDBDE',
			200: '#FFBDC2',
			300: '#FF99A2',
			400: '#FF7A86',
			500: '#E84E62',
			600: '#EB0014',
			700: '#C70011',
			800: '#94000D',
			900: '#570007',
			main: '#E84E62',
			light: '#FF99A2',
			dark: '#C70011',
			contrastText: '#fff',
		},
		success: {
			50: '#E9FBF0',
			100: '#C6F6D9',
			200: '#9AEFBC',
			300: '#6AE79C',
			400: '#3EE07F',
			500: '#59A089',
			600: '#1DB45A',
			700: '#1AA251',
			800: '#178D46',
			900: '#0F5C2E',
			main: '#59A089',
			light: '#6AE79C',
			dark: '#1AA251',
			contrastText: '#fff',
		},
		warning: {
			50: '#FFF9EB',
			100: '#FFF3C1',
			200: '#FFECA1',
			300: '#FFDC48',
			400: '#F4C000',
			500: '#F1A967',
			600: '#D18E00',
			700: '#AB6800',
			800: '#8C5800',
			900: '#5A3600',
			main: '#F1A967',
			light: '#FFDC48',
			dark: '#AB6800',
			contrastText: 'rgba(0, 0, 0, 0.87)',
		},
		secondary: {
			main: '#9c27b0',
			light: '#ba68c8',
			dark: '#7b1fa2',
			contrastText: '#fff',
		},
		info: {
			main: '#0288d1',
			light: '#03a9f4',
			dark: '#01579b',
			contrastText: '#fff',
		},
		contrastThreshold: 3,
		tonalOffset: 0.2,
		background: {
			default: '#efecea',
			paper: '#fff',
		},
		action: {
			active: 'rgba(0, 0, 0, 0.54)',
			hover: 'rgba(0, 0, 0, 0.04)',
			hoverOpacity: 0.04,
			selected: 'rgba(0, 0, 0, 0.08)',
			selectedOpacity: 0.08,
			disabled: 'rgba(0, 0, 0, 0.26)',
			disabledBackground: 'rgba(0, 0, 0, 0.12)',
			disabledOpacity: 0.38,
			focus: 'rgba(0, 0, 0, 0.12)',
			focusOpacity: 0.12,
			activatedOpacity: 0.12,
		},
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: '#D9D4D1',
					backgroundImage: 'none',
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					backgroundColor: '#3D4157',
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					'div#root': {
						height: '100vh',
						display: 'flex',
						overflow: 'hidden',
					},
					'header.MuiAppBar-root': {
						minHeight: `${mainAppBarHeight}px`,
						maxHeight: `${mainAppBarHeight}px`,
					},
					'div.MuiToolbar-root.Drawer-Bottom': {
						minHeight: `${bottomToolbarHeight}px`,
						maxHeight: `${bottomToolbarHeight}px`,
					},
					'div.MuiToolbar-root.Drawer-Toolbar': {
						minHeight: `${mainAppBarHeight}px`,
						maxHeight: `${mainAppBarHeight}px`,
					},
					'div.MuiToolbar-root.MainToolbar': {
						minHeight: `${mainToolbarBarHeight}px`,
						maxHeight: `${mainToolbarBarHeight}px`,
					},
					'div.MuiGrid-root.MuiGrid-container': {
						marginLeft: 0,
						marginTop: 16,
						paddingRight: 16,
					},
					'div.MuiFormControl-root.MuiTextField-root': {
						margin: '0px',
						width: '100%',
					},
					'div.DisabledMonaco': {
						pointerEvents: 'none',
						opacity: 0.8,
					},
					scrollbarColor: '#6b6b6b #2b2b2b',
					'&::-webkit-scrollbar, & *::-webkit-scrollbar': {
						backgroundColor: '#2b2b2b',
					},
					'&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
						borderRadius: 8,
						backgroundColor: '#6b6b6b',
						minHeight: 24,
						border: '3px solid #2b2b2b',
					},
					'&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
						backgroundColor: '#959595',
					},
					'&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
						backgroundColor: '#959595',
					},
					'&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
						backgroundColor: '#959595',
					},
					'&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
						backgroundColor: '#2b2b2b',
					},
					'a.react-link': {
						color: 'inherit',
						textDecoration: 'none',
					},
					'a.react-link:hover': {
						textDecoration: 'underline',
					},
				},
			},
		},
	},
});
