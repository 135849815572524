import { Localization } from '@mui/material/locale';

const afZA: Localization = {
	components: {
		MuiBreadcrumbs: {
			defaultProps: {
				expandText: 'Broodkrummel',
			},
		},
		MuiTablePagination: {
			defaultProps: {
				getItemAriaLabel: type => {
					if (type === 'first') {
						return 'Gaan na eerste bladsy';
					}
					if (type === 'last') {
						return 'Gaan na laatste bladsy';
					}
					if (type === 'next') {
						return 'Gaan na volgende bladsy';
					}
					// if (type === 'previous') {
					return 'Gaan na vorige bladsy';
				},
				labelRowsPerPage: 'Reëls per bladsy:',
				labelDisplayedRows: ({ from, to, count }) =>
					`${from}–${to} van ${count !== -1 ? count : `meer as ${to}`}`,
			},
		},
		MuiRating: {
			defaultProps: {
				getLabelText: value => `${value} Ster${value !== 1 ? 'ren' : ''}`,
				emptyLabelText: 'Leeg',
			},
		},
		MuiAutocomplete: {
			defaultProps: {
				clearText: 'Vee uit',
				closeText: 'Maak toe',
				loadingText: 'Besig om te laai…',
				noOptionsText: 'Geen opsies',
				openText: 'Maak oop',
			},
		},
		MuiAlert: {
			defaultProps: {
				closeText: 'Maak toe',
			},
		},
		MuiPagination: {
			defaultProps: {
				'aria-label': 'Navigasie via paginering',
				getItemAriaLabel: (type, page, selected) => {
					if (type === 'page') {
						return `${selected ? '' : 'Gaan na '}bladsy ${page}`;
					}
					if (type === 'first') {
						return 'Gaan na eerste bladsy';
					}
					if (type === 'last') {
						return 'Gaan na laatste bladsy';
					}
					if (type === 'next') {
						return 'Gaan na volgende bladsy';
					}
					// if (type === 'previous') {
					return 'Gaan na vorige bladsy';
				},
			},
		},
	},
};

export default afZA;
