export default class AppRoutes {
	public static readonly Root = '/studio';
	public static readonly Login = '/studio/login';
	public static readonly Dashboard = '/studio/dashboard';
	public static readonly ForgotPassword = '/studio/forgot-password';
	public static readonly CreateFlowWizard = '/studio/create-flow-wizard';
	public static CreateIntegrationWizard(clientSpaceID?: string, projectSpaceID?: string) {
		return `/studio/create-integration-wizard/${clientSpaceID ?? ':clientSpaceID'}/${
			projectSpaceID ?? ':projectSpaceID'
		}`;
	}
	public static readonly Settings = '/studio/settings';
	public static readonly SignUp = '/studio/sign-up';
	public static readonly FlowList = '/studio/flow-list';
	public static readonly Triggers = '/studio/triggers';
	public static TriggerEditView(flowId?: string, taskId?: string) {
		return `${AppRoutes.Triggers}/edit/${flowId ?? ':flowId'}/${taskId ?? ':taskId'}`;
	}
	public static ConfigFlowView(flowId?: string) {
		return `/studio/flow-list/flow/${flowId ?? ':flowId'}`;
	}
	public static ConfigFlowEditView(flowId?: string) {
		return `/studio/flow-list/flow/${flowId ?? ':flowId'}/edit`;
	}
	public static FlowTask(flowId?: string, microtaskId?: string) {
		return `/studio/flow/${flowId ?? ':flowId'}/microtask/${microtaskId ?? ':microtaskId'}`;
	}
	public static ClientSpaceRoute(clientSpaceID?: string) {
		return `/studio/client-space/${clientSpaceID ?? ':clientSpaceID'}`;
	}
	public static ProjectSpace(clientSpaceID?: string, projectSpaceID?: string) {
		return `${AppRoutes.ClientSpaceRoute(clientSpaceID)}/project-space/${
			projectSpaceID ?? ':projectSpaceID'
		}`;
	}
	public static IntegrationSpace(clientSpaceID?: string, projectSpaceID?: string, flowId?: string) {
		return `${AppRoutes.ProjectSpace(clientSpaceID, projectSpaceID)}/integration-space/${
			flowId ?? ':flowId'
		}`;
	}
	public static IntegrationSpaceEdit(
		clientSpaceID?: string,
		projectSpaceID?: string,
		flowId?: string
	) {
		return `${AppRoutes.ProjectSpace(clientSpaceID, projectSpaceID)}/integration-space/${
			flowId ?? ':flowId'
		}/Edit`;
	}
	public static IntegrationTask(
		clientSpaceID?: string,
		projectSpaceID?: string,
		flowId?: string,
		microtaskId?: string
	) {
		return `${AppRoutes.IntegrationSpace(clientSpaceID, projectSpaceID, flowId)}/microtask/${microtaskId ?? ':microtaskId'}`;
	}
	public static UserManagement() {
		return '/studio/user-management';
	}
	public static RoleManagement() {
		return '/studio/role-management';
	}
	public static Unauthorized() {
		return '/studio/unauthorized';
	}
	public static AccessRequests() {
		return '/studio/access-requests';
	}
	static ApiKeyManagement(): string {
		return '/studio/api-key-management';
	}
	static SecretsManagement(): string {
		return '/studio/secrets-management';
	}
	static SecretEdit(secretID?: string): string {
		return `/studio/secrets-management/edit/${secretID ?? ':secretID'}`;
	}
	static ProfileDashboard(): string {
		return '/studio/profile-dashboard';
	}
}
