import { urlCombine } from 'ihub/common';
import { GetSessionUser } from '../components/main-container/common-functions';
import AppRoutes from '../enums/app-routes';
import dispatchSnackbar from '../functions/dispatch-snackbar';
import HttpResponseOptions from '../interface/http-response-options';
import HttpHelper from './http-helper';
import Parameter from './query-parameter';

export const coreAPI = urlCombine(import.meta.env.VITE_IHUB_URL, import.meta.env.VITE_IHUB_PREFIX);
export const microServiceAPI = urlCombine(
	import.meta.env.VITE_IHUB_MICROSERVICE_URL,
	import.meta.env.VITE_IHUB_MICROSERVICE_PREFIX
);

export const translationServiceAPI = urlCombine(coreAPI, 'Translation');
export const authAPI = urlCombine(coreAPI, 'Auth');
export const schemaServiceAPI = urlCombine(coreAPI, 'DataSchema');

export const microTaskRunnerAPI = urlCombine(microServiceAPI, 'TaskExecutorController');

export const headers = {
	'Content-Type': 'application/json',
};

export const requestMode: RequestMode = 'cors';

export const isURLSet = () => {
	if (!coreAPI || !microServiceAPI) {
		throw new Error('iHub URL has not been configured');
	}
};

export const validateOkResponse = async (
	response: Response,
	request: string,
	objectType: string
) => {
	if (response.status === 200 || response.status === 201) return true;
	const result = await response.clone().json();
	let message = response.statusText;
	if (Object.hasOwn(result, 'message')) {
		message = result.message;
	}
	if (response.status === 401 || response.status === 403) {
		dispatchSnackbar({
			message: `You do not have permission to ${request} ${objectType}.`,
			severity: 'error',
		});
		window.location.pathname = AppRoutes.Unauthorized();
		return;
	} else if (response.statusText) {
		dispatchSnackbar({
			message: message,
			severity: 'error',
		});
	}
	throw new Error(message);
};

export const handleResponse = async (
	response: Response,
	request: string,
	objectType: string,
	options?: HttpResponseOptions
) => {
	try {
		const isOK = await validateOkResponse(response, request, objectType);
		if (isOK && (response.status === 200 || response.status === 201) && options?.success) {
			const clone = response.clone();
			if (clone.headers.get('Content-Type')?.includes('application/json')) {
				const jsonRes = await response.clone().json();
				options.success(jsonRes);
				return;
			}
			options.success(response.clone().ok);
			return;
		}
	} catch (err) {
		const result = await response.clone().json();
		let message = response.statusText;
		if (Object.hasOwn(result, 'message')) {
			message = result.message;
		}
		if (options?.error) {
			options.error(message ?? err);
		} else {
			dispatchSnackbar({
				message: `Failed ${request} on ${objectType} - ${message}`,
				severity: 'error',
			});
		}
	}
};

export const buildURL = (
	objectType: string,
	pathParameters: string[] = [],
	queryParams?: Parameter[]
) => {
	isURLSet();
	let url = urlCombine(coreAPI, objectType, ...pathParameters);
	if (queryParams && queryParams.length > 0) {
		url += `?` + queryParams.map(x => `${x.key}=${x.value}`).join('&');
	}
	return url;
};
