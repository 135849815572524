import { Badge, Box, IconButton, SxProps, Theme, Typography, useTheme } from '@mui/material';
import IconLoader from '../../util-components/icon-loader/icon-loader';
import LightTooltip from '../tooltip/light-tooltip';
import TooltipPlacement from '../tooltip/tooltip-placement.enum';
import IconButtonWrapperProps from './icon-button-wrapper-props';

export default function IconButtonWrapper({
	id,
	label,
	icon,
	onClick,
	size,
	ariaLabel,
	sx,
	badgeIcon,
	variant,
	disabled,
	toolTip,
}: Readonly<IconButtonWrapperProps>) {
	const theme = useTheme();
	const getStyle = (): SxProps<Theme> => {
		let color = theme.palette.text.primary;
		let backgroundColor = 'transparent';
		let hoverColor = theme.palette.primary.dark;

		if (variant === 'light') {
			color = theme.palette.grey.A100;
			backgroundColor = theme.palette.primary.main;
			hoverColor = theme.palette.primary.light;
		}

		const defaultStyle: SxProps<Theme> = {
			color: color,
			borderRadius: 1,
			m: 0,
			py: 0,
			mr: 1,
			backgroundColor: backgroundColor,
			':hover': {
				backgroundColor: hoverColor,
			},
		};

		return { ...defaultStyle, ...sx };
	};

	return (
		<LightTooltip title={toolTip ?? ariaLabel ?? label} placement={TooltipPlacement.Bottom}>
			<Box sx={{ m: 0, p: 0 }}>
				<IconButton
					id={id}
					aria-label={ariaLabel}
					size={size}
					onClick={onClick}
					sx={getStyle()}
					disabled={disabled}
				>
					{badgeIcon ? (
						<Badge badgeContent={4} color="secondary" sx={{ mr: 1 }}>
							<IconLoader icon={icon} />
						</Badge>
					) : (
						<IconLoader sx={{ mr: label === undefined ? 0 : 1 }} icon={icon} />
					)}
					{typeof label === 'string' ? <Typography>{label}</Typography> : label}
				</IconButton>
			</Box>
		</LightTooltip>
	);
}
