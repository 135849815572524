import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import availableThemes from '../theme/available-themes';
import GlobalAppContextProps from './components/custom-controls/local-selector/global-app-context-props';
import GlobalAppContext from './components/custom-controls/local-selector/local-them-context';
import SupportedLocales from './components/custom-controls/local-selector/supported-locales-type';
import SnackbarWrapper from './components/custom-controls/snackbar-wrapper/snackbar-wrapper';
import AvailableThemes from './components/custom-controls/theme-selector/available-themes-type';
import LoadingComponent from './components/util-components/loading-component/loading-component';
import translator from './localization/custom-localizations';
import locales from './localization/mui-localizations';
import privateRoutes from './routes/private-routes';
import publicRoutes from './routes/public-routes';
import createEmotionCache from './utils/create-emotion-cache';
import QueryClientWrapper from './utils/create-query-client';

//https://mui.com/material-ui/getting-started/overview/

export default function App() {
	useEffect(() => {
		async function loadTranslator() {
			await translator;
		}
		loadTranslator();
	}, []);

	const emotionCache = createEmotionCache();
	const themeKey = 'Theme';
	const localeKey = 'Locale';
	const [theme, setTheme] = useState<AvailableThemes>(
		(localStorage.getItem(themeKey) ?? 'defaultDarkTheme') as AvailableThemes
	);
	const [locale, setLocale] = useState<SupportedLocales>(
		(localStorage.getItem(localeKey) ?? 'enUS') as SupportedLocales
	);

	useMemo(() => {
		if (locale) {
			localStorage.setItem(localeKey, locale);
		}
	}, [locale]);

	useMemo(() => {
		if (theme) {
			localStorage.setItem(themeKey, theme);
		}
	}, [theme]);

	const { i18n } = useTranslation();
	const themeWithLocale = useMemo(() => {
		if (locale && theme) {
			if (i18n?.changeLanguage) {
				i18n.changeLanguage(locale.substring(0, 2));
			}
			return createTheme(availableThemes[theme], locales[locale]);
		}
		return createTheme(availableThemes['defaultDarkTheme'], locales['enUS']);
	}, [locale, theme]);

	const router = createBrowserRouter([...privateRoutes, ...publicRoutes]);

	const globalAppContextProps: GlobalAppContextProps = useMemo(() => {
		return {
			theme: theme,
			setTheme: setTheme,
			local: locale,
			setLocal: setLocale,
		};
	}, [theme, locale]);

	return (
		<GlobalAppContext.Provider value={globalAppContextProps}>
			<QueryClientProvider client={QueryClientWrapper.queryClient}>
				<ReactQueryDevtools initialIsOpen={false} />
				<ThemeProvider theme={themeWithLocale}>
					<CacheProvider value={emotionCache}>
						<LocalizationProvider dateAdapter={AdapterLuxon}>
							<CssBaseline />
							<Suspense fallback={<LoadingComponent />}>
								<RouterProvider router={router} />
							</Suspense>
							<SnackbarWrapper />
						</LocalizationProvider>
					</CacheProvider>
				</ThemeProvider>
			</QueryClientProvider>
		</GlobalAppContext.Provider>
	);
}
