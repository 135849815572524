import { UserConfig } from 'ihub/common';
import { NavigateFunction } from 'react-router-dom';
import AppRoutes from '../../enums/app-routes';
import QueryClientWrapper from '../../utils/create-query-client';

export const isStringPropValid = (value?: string) => {
	if (!value || value === '') {
		return false;
	}
	return true;
};

export const emailValidation = (value: string | undefined): string | undefined => {
	if (!value) {
		return 'No email has been provided.';
	}
	const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
	if (emailRegex.test(value)) {
		return undefined;
	}
	return `${value} is not a valid email.`;
};

export const passwordValidation = (value: string | undefined): string | undefined => {
	if (!value) {
		return 'No password has been provided.';
	}
	const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*()_])[\w!@#$%^&*()]{6,50}$/;
	if (passwordRegex.test(value)) {
		return undefined;
	}
	return `The provided password does not meet the complexity requirements.`;
};

const CookieUser = 'User';

export function GetSessionToken(): string {
	const authCookie = document.cookie.split(';').find(x => x.trim().startsWith('ACCESS_TOKEN'));
	if (!authCookie) {
		throw new Error('No auth token present.');
	}
	return authCookie.replace('ACCESS_TOKEN=s%3A', '').trim();
}

export function GetSessionUser(): UserConfig | undefined {
	const res = sessionStorage.getItem(CookieUser);
	if (!res) {
		return undefined;
	}
	return JSON.parse(res);
}

export function ClearSessionUser() {
	sessionStorage.removeItem(CookieUser);
}

export const signInSuccess = (user: UserConfig, navigate: NavigateFunction) => {
	QueryClientWrapper.queryClient.invalidateQueries();
	sessionStorage.setItem(CookieUser, JSON.stringify(user));
	navigate(AppRoutes.Dashboard);
};
