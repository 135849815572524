import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
	interface TypographyVariants {
		display: React.CSSProperties;
		labelLarge: React.CSSProperties;
		labelMedium: React.CSSProperties;
		labelStrong: React.CSSProperties;
		labelSmall: React.CSSProperties;
		bodyLarge: React.CSSProperties;
		bodyStrong: React.CSSProperties;
		bodyMedium: React.CSSProperties;
		bodySmall: React.CSSProperties;
		stepText: React.CSSProperties;
	}
	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		display?: React.CSSProperties;
		labelLarge?: React.CSSProperties;
		labelMedium?: React.CSSProperties;
		labelStrong?: React.CSSProperties;
		labelSmall?: React.CSSProperties;
		bodyLarge?: React.CSSProperties;
		bodyStrong?: React.CSSProperties;
		bodyMedium?: React.CSSProperties;
		bodySmall?: React.CSSProperties;
		stepText?: React.CSSProperties;
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		display: true;
		labelLarge: true;
		labelMedium: true;
		labelStrong: true;
		labelSmall: true;
		bodyLarge: true;
		bodyStrong: true;
		bodyMedium: true;
		bodySmall: true;
		stepText: true;
	}
}

declare module '@mui/material/styles/createPalette' {
	interface CommonColors {
		custom: {
			iHubBlue: string;
			navActiveBackground: string;
			componentBackground: string;
			stepperBackground: string;
			warningBackground: string;
			errorBackground: string;
			linkText: string;
		};
	}
}

//https://zenoo.github.io/mui-theme-creator/
const baseTheme = createTheme({
	typography: {
		fontFamily: 'Inter',
		// DISPLAY
		display: {
			fontWeight: 400,
			fontSize: '2.25rem',
			lineHeight: '2.75rem',
		},
		// HEADLINE LARGE
		h1: {
			fontWeight: 400,
			fontSize: '2rem',
			lineHeight: '2.5rem',
		},
		// HEADLINE MEDIUM
		h2: {
			fontWeight: 400,
			fontSize: '1.75rem',
			lineHeight: '2.25rem',
		},
		// HEADLINE SMALL
		h3: {
			fontWeight: 400,
			fontSize: '1.5rem',
			lineHeight: '2rem',
		},
		// TITLE LARGE
		h4: {
			fontWeight: 500,
			fontSize: '1.375rem',
			lineHeight: '1.75rem',
		},
		// TITLE MEDIUM
		h5: {
			fontWeight: 500,
			fontSize: '1rem',
			lineHeight: '1.5rem',
			letterSpacing: '0.009rem',
		},
		// TITLE SMALL
		h6: {
			fontWeight: 500,
			fontSize: '0.875rem',
			lineHeight: '1.25rem',
			letterSpacing: '0.006',
		},
		// LABEL LARGE
		labelLarge: {
			fontWeight: 400,
			fontSize: '0.875rem',
			lineHeight: '1.25rem',
			letterSpacing: '0.006rem',
		},
		// LABEL MEDIUM
		labelMedium: {
			fontWeight: 500,
			fontSize: '0.75rem',
			lineHeight: '1rem',
		},
		// LABEL STRONG
		labelStrong: {
			fontWeight: 700,
			fontSize: '0.688rem',
			lineHeight: '1rem',
		},
		// LABEL SMALL
		labelSmall: {
			fontWeight: 400,
			fontSize: '0.625rem',
			lineHeight: '1rem',
		},
		// BODY LARGE
		bodyLarge: {
			fontWeight: 400,
			fontSize: '1rem',
			lineHeight: '1.5rem',
			letterSpacing: '0.031rem',
		},
		// BODY STRONG
		bodyStrong: {
			fontWeight: 700,
			fontSize: '0.875rem',
			lineHeight: '1.375rem',
			letterSpacing: '0.009rem',
		},
		// BODY MEDIUM
		bodyMedium: {
			fontWeight: 500,
			fontSize: '0.875rem',
			lineHeight: '1.375rem',
			letterSpacing: '0.009rem',
		},
		// BODY SMALL
		bodySmall: {
			fontWeight: 500,
			fontSize: '0.75rem',
			lineHeight: '1.25rem',
			letterSpacing: '0.063rem',
		},
		// CAPTION
		caption: {
			fontWeight: 400,
			fontSize: '0.75rem',
			lineHeight: '1rem',
			letterSpacing: '0.025rem',
		},
		// BUTTON
		button: {
			fontWeight: 500,
			fontSize: '0.875rem',
			lineHeight: '0.875rem',
			letterSpacing: '0.006',
		},
		// STEP TEXT
		stepText: {
			fontWeight: 400,
			fontSize: '0.563rem',
			lineHeight: '0.844rem',
		},
	},
});

export default baseTheme;
