import i18next from 'i18next';
import backend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { translationServiceAPI } from '../classes/http-helper-const';

const translator = i18next
	.use(backend)
	.use(initReactI18next)
	.init({
		lng: 'en',
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		ns: [],
		defaultNS: ['Root'],
		backend: {
			allowMultiLoading: false,
			crossDomain: true,
			loadPath: `${translationServiceAPI}/{{lng}}/{{ns}}`,
		} as HttpBackendOptions,
		// debug: true
	});
export default translator;
