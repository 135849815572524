enum TranslationNamespaces {
	Root = 'Root',
	FlowList = 'FlowList',
	MainToolbar = 'MainToolbar',
	MainAppDrawer = 'MainAppDrawer',
	MainDashboard = 'MainDashboard',
	ClientSpace = 'ClientSpace',
	ProjectSpace = 'ProjectSpace',
	Integration = 'Integration',
	MainStepper = 'MainStepper',
	FlowView = 'FlowView',
	FlowMicroTaskList = 'FlowMicroTaskList',
	DataMapper = 'DataMapper',
	MicroTaskEditor = 'MicroTaskEditor',
	HubSpotCompanyClientService = 'HubSpotCompanyClientService',
	RestApiSender = 'RestApiSender',
	IfGateService = 'IfGateService',
	HubSpotWebhookService = 'HubSpotWebhookService',
	HubSpotContactClientService = 'HubSpotContactClientService',
	RestReceiver = 'RestReceiver',
	EditDiagramView = 'EditDiagramView',
	Variables = 'Variables',
	LoopTask = 'LoopTask',
	Unauthorized = 'Unauthorized',
	RequestAccessPage = 'RequestAccessPage',
	SecurityManagementView = 'SecurityManagementView',
}
export default TranslationNamespaces;
