enum IconSelector {
	Home,
	Assignment,
	BarChart,
	Dashboard,
	OpenWith,
	OpenInNew,
	AccountTree,
	Person,
	Groups,
	EventRepeat,
	SettingsApplications,
	Settings,
	Api,
	CropFree,
	ChevronRight,
	Delete,
	Add,
	AddBox,
	Close,
	Menu,
	Notifications,
	ChevronLeft,
	ExpandMore,
	QuestionMark,
	KeyboardDoubleArrowLeft,
	KeyboardDoubleArrowRight,
	KeyboardDoubleArrowDown,
	KeyboardDoubleArrowUp,
	KeyboardArrowDown,
	KeyboardArrowRight,
	ErrorOutlined,
	ErrorOutlineOutlined,
	CheckCircle,
	Search,
	AccessTimeFilledOutlined,
	FormatListBulletedOutlined,
	RuleOutlinedIcon,
	AbcOutlinedIcon,
	DataObjectOutlinedIcon,
	PinOutlinedIcon,
	List,
	Fullscreen,
	FullscreenExit,
	ArrowDropDownIcon,
	ArrowRightIcon,
	Refresh,
	Save,
	AddToPhotos,
	Sync,
	CheckCircleOutline,
	InfoIcon,
	MergeIcon,
	ContentCopyIcon,
	Warning,
	PlayArrow,
	ManageAccountsIcon,
	Visibility,
	VisibilityOff,
	Edit,
	Group,
	Key,
	Google,
	SkipPrevious,
	SkipNext,
	DataArray,
	Event,
	CancelIcon,
	Lock,
	Upload,
	AutoFixHigh,
}
export default IconSelector;
